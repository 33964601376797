<template>
  <div id="infinite-list" style="margin-bottom: 100px;">
    <Competition v-show="!highlights" v-for="(l, index) in competitions" v-bind:key="getLeagueKey(l, index)"
      v-bind:competition="l" v-bind:is_visible="true" v-bind:upcoming="upcoming" v-bind:today="today"
      v-bind:esport="esport" v-bind:highlights="highlights" v-bind:live="live" v-bind:date="date">
    </Competition>
    <div v-if="fixtures && fixtures.length > 0">
      <Highlight v-for="(l, index) in fixtures" v-bind:key="getLeagueKey(l, index)" v-bind:fixture="l">
      </Highlight>
    </div>

    <div class="no-fixtures-message" v-else>
      No fixtures available.
    </div>

    <Shimmer v-show="is_busy"></Shimmer>

    <Shimmer v-show="is_busy"></Shimmer>
    <!-- <Waiting v-if="!gamesAvailable || !gamesLive"/> -->

    <scroll-loader v-if="page < 5" v-show="highlights || upcoming || today" :loader-method="loadOnScroll"
      :loader-disable="is_busy">
      <!--      <div class="c-a-2">Loading...</div>-->
    </scroll-loader>
    <div style="margin-bottom: 5px;" v-show="page > 4" v-if="!is_busy"
      class="text-center pt-2 btn-odd hover-pointer text-success" @click="activateLoadOnScroll">
      <span class="btn btn-warning">Load More</span>
    </div>
    <div class="text-light text-center" v-show="no_results">
      No Results found
    </div>
    <br />
  </div>
</template>

<script>
const Competition = () => import("./Competition.vue");
const Highlight = () => import("./Highlight.vue");
import Vue from "vue";
import ScrollLoader from "vue-scroll-loader";
import Shimmer from '../components/shimmer/Shimmer.vue'
// import Waiting from "@/components/Waiting.vue";

Vue.use(ScrollLoader);

import axios from "@/services/api";
import fix from "@/services/fix";
import mqtt from "mqtt/dist/mqtt";

export default {
  name: "Games",
  mounted: function () {
    var vm = this;
    this.resetFilters();

    vm.market_outcome_headers = this.$store.state.market_outcome;

    this.is_busy = true;
    vm.visible_leagues = [];

    // keep a list of opened/expanded leagues
    this.EventBus.$on("event:leagues:show", function (id) {
      var exists = false;
      vm.jQuery.each(vm.visible_leagues, function (k, v) {
        if (parseInt(v) === parseInt(id)) {
          exists = true;
        }
      });

      if (!exists) {
        vm.visible_leagues.push(parseInt(id));
      }
    });

    this.initMqtt(this.sport_id);

    this.$nextTick(function () {
      vm.autoRefreshUI(this.$vnode.tag);
    });

    //setInterval(function (){

    if (vm.searchable) {
      if (vm.search.length > 0) {
        vm.getSearch();
      } else {
        vm.is_busy = false;
      }
    } else if (vm.live) {
      vm.getLive();
    } else if (vm.esport) {
      vm.getEsport();
    } else if (vm.highlights) {
      vm.is_busy = false;
      vm.getHighlight();
    } else if (vm.today) {
      // vm.getTodayFixtures();
      vm.getToday();
    } else if (vm.upcoming) {
      vm.getUpcoming();
    } else if (vm.leo) {
      vm.live = true;
      vm.getAllLive();
    } else {
      vm.getCompetitions();
    }

    //},1000 * 30);
  },
  methods: {
    activateLoadOnScroll: function () {
      // this.load_on_scroll = true;
      this.per_page = 100;
      this.loadOnScroll();
    },
    resetGames: function () {
      var vm = this;

      if (vm.highlights) {
        vm.page = 0;
        vm.last_page = 0;
        vm.remaining_records = 0;
        vm.highlightsRawData = [];
        vm.getHighlight();
        return;
      }

      if (vm.upcoming) {
        vm.upcoming_page = 0;
        vm.upcoming_last_page = 0;
        vm.upcoming_remaining_records = 0;
        vm.upcomingRawData = [];
        vm.getUpcoming();
        return;
      }

      if (vm.today) {
        vm.today_page = 0;
        vm.today_last_page = 0;
        vm.today_remaining_records = 0;
        vm.todayRawData = [];
        vm.getNewToday();
        return;
      }
    },
    loadOnScroll: function () {
      var vm = this;

      if (vm.highlights) {
        vm.getHighlight();
      }

      if (vm.upcoming) {
        vm.getUpcoming();
      }

      if (vm.today) {
        vm.getToday();
      }
    },
    registerPoll: function () {
      var market_id = this.market_id == 0 ? 1 : this.market_id;
      var sport_id = this.sport_id == 0 ? 1 : this.sport_id;

      // subcribe to specific sportID (this.sport_id) and all matches but only specific match this.market_id odds changes
      var topic_betstop =
        "topic/producer-3/sport-" + sport_id + "/status/+/market-" + market_id;

      if (this.live) {
        topic_betstop =
          "topic/producer-1/sport-" +
          sport_id +
          "/status/+/market-" +
          market_id;
      }

      this.poll(topic_betstop);
    },
    initMqtt: function () {
      var endpoint = "wss://ws-v2.ponyoka.com/mqtt";

      var vm = this;

      if (this.mqttClient !== false) {
        this.mqttClient.end();
      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: "ui", //process.env.VUE_APP_URL_MQTT_USER,
        password: "mayui", //process.env.VUE_APP_URL_MQTT_PASS,
      };

      var market_id = this.market_id == 0 ? 1 : this.market_id;
      var sport_id = this.sport_id === 0 ? 1 : this.sport_id;

      // subcribe to specific sportID (this.sport_id) and all matches but only specific match this.market_id odds changes
      var topic_betstop =
        "topic/producer-3/sport-" + sport_id + "/status/+/market-" + market_id;
      var topic_others = "topic/producer-3/sport-" + sport_id + "/betstop/+";
      //var topic_match = 'topic/producer-3/sport-'+sport_id+'/match/+';
      var topic_match = "topic/producer-3/sport-" + sport_id + "/status/+";
      // var topic_producer = 'topic/producer-3';

      if (this.live) {
        topic_others = "topic/producer-1/sport-" + sport_id + "/betstop/+";
        topic_betstop =
          "topic/producer-1/sport-" +
          sport_id +
          "/status/+/market-" +
          market_id;
        topic_match = "topic/producer-1/sport-" + sport_id + "/status/+";
        //topic_producer = 'topic/producer-1';
      }

      var client = mqtt.connect(endpoint, options);
      // console.log("url for websocket", endpoint);

      client.on("connect", function () {
        client.subscribe(topic_betstop, function (err) {
          if (!err) {
            // console.log("subscribed to topic " + topic_betstop);
          }
        });

        client.subscribe(topic_others, function (err) {
          if (!err) {
            // console.log("subscribed to topic " + topic_others);
          }
        });

        client.subscribe(topic_match, function (err) {
          if (!err) {
            // console.log("subscribed to topic " + topic_match);
          }
        });

        var topic2 = "topic/producer";
        client.subscribe(topic2, function (err) {
          if (!err) {
            // console.log("subscribed to topic " + topic2);
          }
        });
      });

      client.on("message", function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      });

      this.mqttClient = client;
    },
    getKey: function (fixture, index) {
      if (Array.isArray(fixture)) {
        var currentFixture = fixture[0];
      } else {
        currentFixture = fixture;
      }

      var prefix =
        currentFixture === undefined || currentFixture.match_id == undefined
          ? index
          : currentFixture.match_id;
      return Math.random()
        .toString(10)
        .replace("0.", "fixture-id-" + prefix + "-");
    },
    getLeagueKey: function (league, index) {
      var prefix =
        league === undefined || league.competition_id == undefined
          ? index
          : league.competition_id;
      return Math.random()
        .toString(10)
        .replace("0.", "competition-id-" + prefix + "-");
    },
    getCompetitions: function () {
      var vm = this;
      var endpoint = process.env.VUE_APP_URL_TOP_LEAGUES;

      if (this.live) {
        endpoint = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';
        endpoint = endpoint.replace("{sport_id}", vm.sport_id)
      }

      if (this.esport) {
        endpoint = process.env.VUE_APP_URL_ESPORT_TOP_LEAGUES;
      }

      var path = endpoint.replace(
        "{sport_id}",
        parseInt(vm.sport_id) > 0 ? vm.sport_id : 1
      );
      path = path.replace("{count}", 0);

      var games_filter = {};

      if (this.date.length > 0) {
        games_filter.date = this.date;
      }

      if (parseInt(this.upcoming) > 0) {
        games_filter.upcoming = this.upcoming;
        games_filter.highlights = "";
      }

      games_filter.hour = vm.hour;
      games_filter.hours = vm.hour;

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        match_live_status: 1,
        tournament_id: vm.competition_id,
        category_id: vm.category_id,
      };

      fix.get(path, { params: dat })
        .then((res) => {
          var leagues = res.data.data;

          if (vm.highlight || vm.highlight) {
            vm.jQuery.each(leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;

              var exist = false;

              // check if league exists in current leagues
              vm.jQuery.each(vm.leagues, function (kk, vv) {
                if (vv.competition_id === v.competition_id) {
                  exist = true;
                }
              });

              if (!exist) {
                vm.leagues.push(v);
              }
            });
          } else {
            vm.leagues = leagues;
            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });
          }

          vm.$store.dispatch("setLeague", vm.leagues);

          vm.getGames();
          vm.autoRefreshUI(vm.$vnode.tag);
        })
        .catch((error) => {
          console.log(error);
        });
    },


    getSearch: function () {
      var vm = this;
      vm.leagues = [];
      vm.competitions = [];
      vm.is_busy = true;

      var path = process.env.VUE_APP_URL_SEARCH;

      path = path.replace("{sport_id}", 1);

      axios
        .post(path, JSON.stringify({ search: vm.search }))
        .then((res) => {
          vm.is_busy = false;

          var games = res.data.message;
          vm.leagues = games.competitions;
          //console.log(`Search --> ${JSON.stringify(vm.leagues)}`);

          vm.jQuery.each(vm.leagues, function (k, v) {
            v.fixtures = [];
            v.is_busy = false;
            v.is_visible = false;
            v.has_fixture = false;
            vm.leagues[k] = v;
          });

          vm.visible_leagues = [];

          var results = games.data;
          vm.jQuery.each(vm.leagues, function (k, v) {
            // get fixtures
            var fx = [];

            vm.jQuery.each(results, function (kk, vv) {
              if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                fx.push(vv);
              }
            });

            v.fixtures = fx;
            v.is_visible = true;
            vm.leagues[k] = v;
          });

          vm.competitions = vm.leagues;
          vm.highlightsData = results;
        })
        .catch((err) => {
          vm.busy = false;
          vm.is_busy = false;

          vm.loading = "";

          if (err.response) {
            vm.setError("Failed", err.response.data.message);
            // console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            // console.log(JSON.stringify(err.request));
          } else {
            // console.log(JSON.stringify(err));
          }
        });
    },

    getUpcoming: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/highlights/{sport_id}";
      path = path.replace("{sport_id}", vm.sport_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }

      if (vm.is_busy) {
        return;
      }

      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit("event:busy", true);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id: vm.competition_id,
        category_id: vm.category_id,
        today: 0,
        upcoming: 1
      };

      fix
        .get(path, { params: dat })
        .then((res) => {
          // console.log("Response Payload", res)
          vm.is_busy = false;
          vm.EventBus.$emit("event:busy", false);

          var highlightsRawData = res.data.data;
          vm.last_page = res.data.last_page;
          vm.remaining_records = res.data.remaining_records;

          if (parseInt(vm.page) === 1) {
            vm.fixtures = []; // Reset fixtures array
          }

          highlightsRawData.forEach((highlight) => {
            // Restructure the data and push it to fixtures array
            vm.fixtures.push({
              tournament: highlight.tournament,
              sport_id: highlight.sport_id.toString(),
              game_id: highlight.game_id.toString(),
              name: highlight.name,
              match_id: highlight.match_id.toString(),
              date: highlight.date,
              total_markets: highlight.total_markets,
              ActiveMarkets: highlight.active_markets,
              competition_id: highlight.tournament_id,
              status_code: highlight.status_code,
              status: highlight.status,
              live_coverage: 0,
              market_name: highlight.highlight_market.market_name,
              country: highlight.country,
              category_id: highlight.category_id,
              event_time: "",
              home_score: highlight.home_score,
              match_status: highlight.match_status,
              event_status: "",
              away_score: highlight.away_score,
              competitor2_flag: "",
              competitor1_flag: "",
              home_team: highlight.home_team,
              away_team: highlight.away_team,
              outcomes: highlight.highlight_market.outcomes.map(outcome => ({
                alias: outcome.alias,
                market_name: highlight.highlight_market.market_name,
                market_id: highlight.highlight_market.market_id,
                outcome_name: outcome.outcome_name,
                specifier: highlight.highlight_market.specifier,
                outcome_id: outcome.outcome_id.toString(),
                odd: outcome.odds.toString(),
                odds: outcome.odds.toString(),
                previous_odds: outcome.previous_odds.toString(),
                odd_id: outcome.odd_id.toString(),
                direction: outcome.direction.toString(),
                status: outcome.status,
                active: outcome.active,
                producer_id: highlight.producer_id.toString(),
                producer_status: highlight.producer_status.toString(),
                probability: outcome.probability.toString()
              }))
            });

          });
        })
        .catch((err) => {
          vm.EventBus.$emit("event:busy", false);
          vm.is_busy = false;

          if (err.response) {
            vm.setError("Failed", err.response.data.message);
          } else if (err.request) {
            console.error(JSON.stringify(err.request));
          } else {
            console.error(JSON.stringify(err));
          }
        });
    },

    getHighlight: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/highlights/{sport_id}";
      path = path.replace("{sport_id}", vm.sport_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }

      if (vm.is_busy) {
        return;
      }

      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit("event:busy", true);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id: vm.competition_id,
        category_id: vm.category_id,
        upcoming: 0,
        today: 0
      };

      fix
        .get(path, { params: dat }) // Pass dat as params directly
        .then((res) => {
          // console.log("Data", res)
          vm.is_busy = false;
          vm.EventBus.$emit("event:busy", false);

          var highlightsRawData = res.data.data;
          vm.last_page = res.data.last_page;
          vm.remaining_records = res.data.remaining_records;

          if (parseInt(vm.page) === 1) {
            vm.fixtures = []; // Reset fixtures array
          }

          highlightsRawData.forEach((highlight) => {
            // Restructure the data and push it to fixtures array
            vm.fixtures.push({
              tournament: highlight.tournament,
              sport_id: highlight.sport_id.toString(),
              game_id: highlight.game_id.toString(),
              name: highlight.name,
              match_id: highlight.match_id.toString(),
              date: highlight.date,
              total_markets: highlight.total_markets,
              ActiveMarkets: highlight.active_markets,
              competition_id: highlight.tournament_id,
              status_code: highlight.status_code,
              status: highlight.status,
              live_coverage: 0,
              market_name: highlight.highlight_market.market_name,
              country: highlight.country,
              category_id: highlight.category_id,
              event_time: "",
              home_score: highlight.home_score,
              match_status: highlight.match_status,
              event_status: "",
              away_score: highlight.away_score,
              competitor2_flag: "",
              competitor1_flag: "",
              home_team: highlight.home_team,
              away_team: highlight.away_team,
              outcomes: highlight.highlight_market.outcomes.map(outcome => ({
                alias: outcome.alias,
                market_name: highlight.highlight_market.market_name,
                market_id: highlight.highlight_market.market_id,
                outcome_name: outcome.outcome_name,
                specifier: highlight.highlight_market.specifier,
                outcome_id: outcome.outcome_id.toString(),
                odd: outcome.odds.toString(),
                odds: outcome.odds.toString(),
                previous_odds: outcome.previous_odds.toString(),
                odd_id: outcome.odd_id.toString(),
                direction: outcome.direction.toString(),
                status: outcome.status,
                active: outcome.active,
                producer_id: highlight.producer_id.toString(),
                producer_status: highlight.producer_status.toString(),
                probability: outcome.probability.toString()
              }))
            });
          });
        })
        .catch((err) => {
          vm.EventBus.$emit("event:busy", false);
          vm.is_busy = false;

          if (err.response) {
            vm.setError("Failed", err.response.data.message);
          } else if (err.request) {
            console.error(JSON.stringify(err.request));
          } else {
            console.error(JSON.stringify(err));
          }
        });
    },

    getToday: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/highlights/{sport_id}";
      path = path.replace("{sport_id}", vm.sport_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }

      if (vm.is_busy) {
        return;
      }

      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit("event:busy", true);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id: vm.competition_id,
        category_id: vm.category_id,
        upcoming: 0,
        today: 1
      };

      fix
        .get(path, { params: dat }) // Pass dat as params directly
        .then((res) => {
          // console.log("Data", res)
          vm.is_busy = false;
          vm.EventBus.$emit("event:busy", false);

          var highlightsRawData = res.data.data;
          vm.last_page = res.data.last_page;
          vm.remaining_records = res.data.remaining_records;

          /*if (parseInt(vm.page) === 1) {
            vm.fixtures = [];
          }*/

          highlightsRawData.forEach((highlight) => {
            // Restructure the data and push it to fixtures array
            vm.fixtures.push({
              tournament: highlight.tournament,
              sport_id: highlight.sport_id.toString(),
              game_id: highlight.game_id.toString(),
              name: highlight.name,
              match_id: highlight.match_id.toString(),
              date: highlight.date,
              total_markets: highlight.total_markets,
              ActiveMarkets: highlight.active_markets,
              competition_id: highlight.tournament_id,
              status_code: highlight.status_code,
              status: highlight.status,
              live_coverage: 0,
              market_name: highlight.highlight_market.market_name,
              country: highlight.country,
              category_id: highlight.category_id,
              event_time: "",
              home_score: highlight.home_score,
              match_status: highlight.match_status,
              event_status: "",
              away_score: highlight.away_score,
              competitor2_flag: "",
              competitor1_flag: "",
              home_team: highlight.home_team,
              away_team: highlight.away_team,
              outcomes: highlight.highlight_market.outcomes.map(outcome => ({
                alias: outcome.alias,
                market_name: highlight.highlight_market.market_name,
                market_id: highlight.highlight_market.market_id,
                outcome_name: outcome.outcome_name,
                specifier: highlight.highlight_market.specifier,
                outcome_id: outcome.outcome_id.toString(),
                odd: outcome.odds.toString(),
                odds: outcome.odds.toString(),
                previous_odds: outcome.previous_odds.toString(),
                odd_id: outcome.odd_id.toString(),
                direction: outcome.direction.toString(),
                status: outcome.status,
                active: outcome.active,
                producer_id: highlight.producer_id.toString(),
                producer_status: highlight.producer_status.toString(),
                probability: outcome.probability.toString()
              }))
            });
          });
        })
        .catch((err) => {
          vm.EventBus.$emit("event:busy", false);
          vm.is_busy = false;

          if (err.response) {
            vm.setError("Failed", err.response.data.message);
          } else if (err.request) {
            console.error(JSON.stringify(err.request));
          } else {
            console.error(JSON.stringify(err));
          }
        });
    },

    filterHighlight: function () {
      var vm = this;

      vm.highlightsData = [];

      if (parseInt(vm.competition_id) < 1) {
        vm.highlightsData = vm.highlightsRawData;
        return;
      }

      vm.jQuery.each(vm.highlightsRawData, function (k, v) {
        if (parseInt(vm.competition_id) === parseInt(v.competition_id)) {
          vm.highlightsData.push(v);
        }
      });
    },

    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }
      return parseFloat(x).toFixed(2);
    },

    getLive: function () {
      // console.log("getLive");
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';
      path = path.replace("{sport_id", 1)

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        match_live_status: 1,
      };

      fix.get(path, { params: dat })
        .then((res) => {
          var games = res.data.data;
          vm.leagues = games;

          vm.jQuery.each(vm.leagues, function (k, v) {
            v.fixtures = [];
            v.is_busy = false;
            v.is_visible = false;
            v.has_fixture = false;
            vm.leagues[k] = v;
          });

          vm.$store.dispatch("setLeague", vm.leagues);

          vm.is_busy = false;
          vm.visible_leagues = [];

          var results = games.data;

          vm.jQuery.each(vm.leagues, function (k, v) {
            // get fixtures
            var fx = [];

            vm.jQuery.each(results, function (kk, vv) {
              if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                fx.push(vv);
                vm.EventBus.$emit("event:leagues:show", v.competition_id);
              }
            });

            v.fixtures = fx;
            v.is_visible = true;
            vm.leagues[k] = v;
          });

          vm.competitions = vm.leagues;

          vm.getCompetitions();
        })
        .catch((err) => {
          vm.busy = false;

          vm.loading = "";

          if (err.response) {
            vm.setError("Failed", err.response.data.message);
            // console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            // console.log(JSON.stringify(err.request));
          } else {
            // console.log(JSON.stringify(err));
          }
        });
    },

    getGames: function () {
      var vm = this;

      var c = 10;
      var b = 0;

      var competitions = [];

      this.jQuery.each(vm.leagues, function (k, v) {
        b++;
        var competition_id = v.competition_id;
        // get fixtures

        if (b < c && v.fixtures.length === 0) {
          competitions.push(competition_id);
        }
      });

      vm.getFixture(competitions.join(","));
    },

    reloadUI: function () {
      this.autoRefreshUI(this.$vnode.tag);
    },
  },
  computed: {
    loadingDistance: function () {
      return 50;
    },
    market_outcome: function () {
      return this.market_outcome_headers;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    games: function () {
      return this.$store.state.games;
    },
    allLoaded: function () {
      return (
        this.$store.state.games.current_page > 0 &&
        this.$store.state.games.current_page ===
        this.$store.state.games.last_page
      );
    },
    busy1: function () {
      return this.$store.state.busy;
    },
    gamesAvailable() {
      return this.competitions.length > 0 || this.fixtures.length > 0;
    },
    gamesLive() {
      return this.live > 0;
    },
    country_code: function () {
      return this.$store.state.country_code;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },

    category_id: function () {
      //console.log("CATTTT-->",this.$store.state.category_id)
      return this.$store.state.category_id;
    },
    sport_name: function () {
      return this.$store.state.sport_name;
    },
    market_id: function () {
      return this.$store.state.market_id;
    },
    competition_id: function () {
      return this.$store.state.competition_id;
    },
    hour: function () {
      return this.$store.state.hour;
    },
    profile: function () {
      return this.getProfile();
    },
  },
  components: {
    Competition,
    Highlight,
    Shimmer,
    // Waiting
  },
  data: function () {
    return {
      leagues: [],
      top_leagues: [],
      competitions: [],
      highlightsData: [],
      highlightsRawData: [],
      loading: [],
      visible: [],
      busy: [],
      is_busy: false,
      visible_leagues: [],
      mqttClient: false,
      market_outcome_headers: [],
      per_page: 20,
      page: 0,
      last_page: 0,
      remaining_records: 0,

      today_page: 0,
      today_last_page: 0,
      today_remaining_records: 0,
      todayRawData: [],

      upcoming_page: 0,
      upcoming_last_page: 0,
      upcoming_remaining_records: 0,
      upcomingRawData: [],
      fixtures: [],
    };
  },
  props: {
    date: {
      required: false,
      default: "",
    },
    searchable: {
      required: false,
      default: false,
      type: Boolean,
    },
    search: {
      required: false,
      default: "",
      type: String,
    },
    upcoming: {
      required: false,
      default: false,
    },
    highlights: {
      required: false,
      default: false,
    },
    live: {
      required: false,
      default: false,
    },
    today: {
      required: false,
      default: false,
    },
    leo: {
      required: false,
      default: false,
    },
    sport: {
      required: false,
      default: 1,
    },
    esport: {
      required: false,
      default: false,
    },
  },
  watch: {
    sport_id: function () {
      this.is_busy = false;
      this.resetGames();
      this.initMqtt();
    },
    market_id: function (newValue) {
      console.log("market_id ==> " + newValue);
      this.is_busy = false;
      this.resetGames();
    },
    hour: function (newValue, oldValue) {
      console.log(
        "Hour =>newValue ==> " + newValue + " oldValue ==> " + oldValue
      );

      if (parseInt(newValue) === parseInt(oldValue)) {
        return;
      }

      if (this.highlights) {
        this.per_page = 20;
        this.page = 0;
        this.last_page = 0;
        this.remaining_records = 0;
        this.highlightsRawData = [];
        this.getHighlight();
      } else {
        this.getCompetitions();
      }
    },
    fetchSearchResults() {
      // Assuming you fetch search results here, or wherever it's appropriate
      // After receiving and processing search results, emit an event

      // For example, if you have an array called 'searchResults' containing the search results:
      const searchResults = [
        // ... (your search results data)
      ];

      // Emit an event to notify the parent component (Search) that search results are available
      this.$emit('search-results', searchResults);
    },
    search: function (newValue, oldValue) {
      console.log(
        "search =>newValue ==> " + newValue + " oldValue ==> " + oldValue
      );

      if (newValue !== oldValue && newValue.length > 0) {
        this.getSearch();
      }
    },
    date: function (newValue, oldValue) {
      console.log(
        "date newValue ==> " + newValue + " oldValue ==> " + oldValue
      );

      this.getCompetitions();
    },
    competition_id: function () {
      this.is_busy = false;
      this.resetGames();
    },
    today: function (newValue) {
      if (newValue) {
        this.is_busy = false;
        this.fixtures = this.todayRawData;
        this.getToday();
      }
    },
    upcoming: function (newValue) {
      if (newValue) {
        this.is_busy = false;
        this.fixtures = this.upcomingRawData;
        this.getUpcoming();
      }
    },
    highlights: function (newValue) {
      if (newValue) {
        this.is_busy = false;
        this.fixtures = this.highlightsRawData;
        this.getHighlight();
      }
    },
    country_code: function () {
      this.is_busy = false;
      this.resetGames();
    },

    category_id: function () {
      this.is_busy = false;
      this.resetGames();
    },

  },
  beforeDestroy: function () {
    // console.log("beforeDestroy");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  destroyed: function () {
    // console.log("destroyed");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
};
</script>
